import React, { useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const NewsForm = ({ onSubmit, initialData }) => {
    const [formData, setFormData] = useState({
        titleID: initialData?.titleID,
        titleEN: initialData?.titleEN,
        articleID: initialData?.articleID,
        articleEN: initialData?.articleEN,
        tag: initialData?.tag,
        image: initialData?.image,
        translate: initialData?.translate,
        slug: initialData?.slug,
        imagePreview: initialData?.imagePreview,
    });

    const [formErrors, setFormErrors] = useState({
        titleID: false,
        titleEN: false,
        articleID: false,
        articleEN: false,
        tag: false,
        image: false,
    });

    const generateSlug = (title) => {
        // Convert title to lowercase and remove leading/trailing whitespace
        const trimmedTitle = title.trim().toLowerCase();

        // Remove special characters, replace spaces with hyphens
        const cleanedTitle = trimmedTitle.replace(/[^\w\s-]/g, '').replace(/\s+/g, '-');

        // Remove common stop words (customize this list as needed)
        const stopWords = ['a', 'an', 'the'];
        const words = cleanedTitle.split('-').filter(word => !stopWords.includes(word));

        // Limit the number of words in the slug (adjust as needed)
        const slugWords = words.slice(0, 6); // Limiting to 6 words

        // Join the words with hyphens to form the final slug
        const slug = slugWords.join('-');

        return slug;
    };

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;

        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: type === 'checkbox' ? checked : value,
        }));

        setFormErrors((prevErrors) => ({
            ...prevErrors,
            [name]: false,
        }));

        // Generate slug based on titleID when it changes
        if (name === 'titleID') {
            const slug = generateSlug(value);
            setFormData((prevFormData) => ({
                ...prevFormData,
                slug: slug,
            }));
        }

        if (name === 'image') {
            setFormData((prevFormData) => ({
                ...prevFormData,
                image: null,
            }));
            setFormErrors((prevErrors) => ({
                ...prevErrors,
                image: false,
            }));
        }
    };

    const handleQuillChange = (name, value) => {
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value,
        }));

        setFormErrors((prevErrors) => ({
            ...prevErrors,
            [name]: false,
        }));
    };

    const handleImageChange = (e) => {
        const file = e.target.files[0];

        setFormData({
            ...formData,
            image: file,
            imagePreview: URL.createObjectURL(file),
        });
        setFormErrors((prevErrors) => ({
            ...prevErrors,
            image: false,
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (validateForm()) {
            onSubmit(formData);
        } else {
            console.log('Form validation failed. Please fill all required fields.');
        }
    };

    const validateForm = () => {
        const errors = {
            titleID: formData.titleID.trim() === '',
            titleEN: formData.translate && formData.titleEN.trim() === '',
            articleID: formData.articleID.trim() === '',
            articleEN: formData.translate && formData.articleEN.trim() === '',
            tag: formData.tag.trim() === '',
            image: formData.image === null,
        };

        setFormErrors(errors);

        return !Object.values(errors).some((error) => error);
    };

    const isFormInvalid = !Object.values(formErrors).every((error) => !error);

    return (
        <form onSubmit={handleSubmit} className="p-6 bg-white shadow-md rounded-lg">
            <h2 className="text-2xl font-semibold text-blue-600 mb-6">Create News Article</h2>
            <div className="mb-4">
                <label className="block text-gray-700">News Title (ID):</label>
                <input
                    type="text"
                    name="titleID"
                    value={formData.titleID}
                    onChange={handleChange}
                    className={`mt-1 p-2 border ${formErrors.titleID ? 'border-red-500' : 'border-gray-300'} rounded w-full focus:outline-none focus:ring-2 focus:ring-blue-600`}
                />
                {formErrors.titleID && <p className="text-red-500 text-sm mt-1">Title (ID) is required.</p>}
            </div>
            <div className="mb-4">
                <label className="block text-gray-700">Article (ID):</label>
                <ReactQuill
                    name="articleID"
                    value={formData.articleID}
                    onChange={(value) => handleQuillChange('articleID', value)}
                    className={`mt-1 border ${formErrors.articleID ? 'border-red-500' : 'border-gray-300'} rounded focus:outline-none focus:ring-2 focus:ring-blue-600`}
                />
                {formErrors.articleID && <p className="text-red-500 text-sm mt-1">Article (ID) is required.</p>}
            </div>
            <div className="mb-4">
                <label className="block text-gray-700">Tag:</label>
                <select
                    name="tag"
                    value={formData.tag}
                    onChange={handleChange}
                    className={`mt-1 p-2 border ${formErrors.tag ? 'border-red-500' : 'border-gray-300'} rounded w-full focus:outline-none focus:ring-2 focus:ring-blue-600 bg-white hover:cursor-pointer`}
                >
                    <option value="" disabled>Select Tag</option>
                    <option value="championship">Championship</option>
                    <option value="press-release">Press Release</option>
                    <option value="news">News</option>
                    <option value="event">Event</option>
                </select>
                {formErrors.tag && <p className="text-red-500 text-sm mt-1">Tag is required.</p>}
            </div>
            <div className="mb-4">
                <label className="block text-gray-700">News Image:</label>
                <input
                    type="file"
                    name="image"
                    accept="image/jpeg, image/png"
                    onChange={handleImageChange}
                    className={`mt-1 p-2 border ${formErrors.image ? 'border-red-500' : 'border-gray-300'} rounded w-full focus:outline-none focus:ring-2 focus:ring-blue-600`}
                />
                {formErrors.image && <p className="text-red-500 text-sm mt-1">Image is required.</p>}
                {formData.imagePreview && (
                    <img
                        src={formData.imagePreview}
                        alt="Preview of News"
                        className="mt-2 w-full h-[60vh] object-cover"
                    />
                )}
                {/* Display image if initialData.image exists */}
                {initialData && initialData.image && (
                    <div className="mt-2">
                        <img
                            src={`${process.env.REACT_APP_API}/${initialData.image}`}
                            alt="Preview of News"
                            className="w-full h-[60vh] object-cover"
                        />
                    </div>
                )}
            </div>
            <div className="mb-4">
                <label className="flex items-center text-gray-700">
                    <input
                        type="checkbox"
                        name="translate"
                        checked={formData.translate}
                        onChange={handleChange}
                        className="mr-2 focus:outline-none focus:ring-2 focus:ring-blue-600"
                    />
                    Translate to English
                </label>
            </div>

            {formData.translate && formData.translate > 0 && (
                <>
                    <div className="mb-4">
                        <label className="block text-gray-700">News Title (EN):</label>
                        <input
                            type="text"
                            name="titleEN"
                            value={formData.titleEN}
                            onChange={handleChange}
                            className={`mt-1 p-2 border ${formErrors.titleEN ? 'border-red-500' : 'border-gray-300'} rounded w-full focus:outline-none focus:ring-2 focus:ring-blue-600`}
                        />
                        {formErrors.titleEN && <p className="text-red-500 text-sm mt-1">Title (EN) is required.</p>}
                    </div>
                    <div className="mb-4">
                        <label className="block text-gray-700">Article (EN):</label>
                        <ReactQuill
                            name="articleEN"
                            value={formData.articleEN}
                            onChange={(value) => handleQuillChange('articleEN', value)}

                            className={`mt-1 border ${formErrors.articleEN ? 'border-red-500' : 'border-gray-300'} rounded focus:outline-none focus:ring-2 focus:ring-blue-600`}
                        />
                        {formErrors.articleEN && <p className="text-red-500 text-sm mt-1">Article (EN) is required.</p>}
                    </div>
                </>
            )}

            <button
                type="submit"
                disabled={isFormInvalid}
                className={`w-full bg-blue-600 text-white py-2 px-4 rounded ${isFormInvalid ? 'opacity-50 cursor-not-allowed' : 'hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-600'}`}
            >
                See Preview
            </button>
        </form>
    );
};

export default NewsForm;
