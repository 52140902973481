const IndicatorButtons = ({length, onClick, currentSlide}) => {
    return (
        <>
            <div className="flex mt-8 justify-center">
                {Array.from({
                    length: length,
                }).map((_, index) => (
                    <button
                        key={index}
                        onClick={() => onClick(index + 1)}
                        className={`h-4 bg-slate-300 rounded-full focus:outline-none mr-1 ${
                            index + 1 === currentSlide ? "w-12" : "w-4"
                        }`}
                    ></button>
                ))}
            </div>
        </>
    );
};

export default IndicatorButtons;
