import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";

export default function NewsTable({ isDashboard = false, news, handlePreview }) {

    const navigate = useNavigate();

    const Rows = (props) => {
        const { id, image, titleID, articleID, titleEN, articleEN, date, translate, tag, isDisplayed, handlePreview } = props
        const imageUrl = `${process.env.REACT_APP_API}/${image}`;

        const [showDropdown, setShowDropdown] = useState(false);
        const [showConfirmation, setShowConfirmation] = useState(false); // State for delete confirmation
        const [deleteResponse, setDeleteResponse] = useState('Are you sure you want to delete this?');
        const [isResponseSuccess, setIsResponseSuccess] = useState(false)


        const toggleDropdown = () => {
            setShowDropdown(!showDropdown);
        };

        const handlePreviewClick = () => {
            handlePreview({ id, image, titleID, articleID, titleEN, articleEN, date, translate, isDisplayed, tag });
            setShowDropdown(false); // Close dropdown after selecting an action
        };

        const handleDelete = async () => {
            const token = Cookies.get("token");
            try {
                const response = await fetch(`${process.env.REACT_APP_API}/api/news/${id}`, {
                    method: 'DELETE',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                });

                if (response.ok) {
                    // Handle success response (optional)
                    const data = await response.json();
                    setDeleteResponse(data.message); // Assuming the message is in JSON response
                    setIsResponseSuccess(true)
                } else {
                    // Handle failure response
                    const errorData = await response.json();
                    setDeleteResponse(`Error: ${errorData.error}`);
                }

                setShowConfirmation(true); // Show confirmation popup
                window.location.reload()

            } catch (error) {
                console.error('Error deleting news:', error.message);
                setDeleteResponse(`Network error: ${error.message}`);
                setShowConfirmation(true); // Show confirmation popup with error message
            }
        };

        const handleToggleDisplay = async (id, isDisplayed) => {
            const token = Cookies.get("token");

            try {
                const response = await fetch(`${process.env.REACT_APP_API}/api/news/${id}/display`, {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`
                    },
                    body: JSON.stringify({ isDisplayed }),
                });

                if (response.ok) {
                    const data = await response.json();
                    console.log(data.message); // Log success message
                    // Optionally update state or UI based on response
                } else {
                    console.error('Failed to toggle display:', response.statusText);
                    // Handle error scenario
                }
                setShowDropdown(false)
                window.location.reload()
            } catch (error) {
                console.error('Error toggling display:', error.message);
                // Handle network error or other exceptions
            }
        };

        const handleConfirmationClose = () => {
            setShowConfirmation(false);
            setDeleteResponse('Are you sure you want to delete this?'); // Clear delete response message
        };

        return (
            <>
                <tr className="hover:cursor-pointer hover:bg-sky-100">
                    <td className="px-6 py-2 whitespace-nowrap">
                        <div className="flex items-center">
                            <div className="flex-shrink-0 h-14 w-14">
                                <img className="h-14 w-14 rounded-full object-cover" src={imageUrl}
                                    alt={image} />
                            </div>
                        </div>
                    </td>
                    <td className="px-6 whitespace-nowrap overflow-hidden truncate" style={{ maxWidth: "10rem" }}>
                        {titleID}
                    </td>
                    <td className="px-6 whitespace-nowrap">
                        {new Date(date).toLocaleDateString('en-GB', { day: 'numeric', month: 'long', year: 'numeric' })}
                    </td>
                    <td className="px-6 whitespace-nowrap">
                        <img className={translate ? "w-4" : "w-3"} src={`/icons/${translate ? "check" : "x"}.svg`} alt={titleID} />
                    </td>
                    <td className="px-6 whitespace-nowrap">
                        <img className={isDisplayed ? "w-4" : "w-3"} src={`/icons/${isDisplayed ? "check" : "x"}.svg`} alt={isDisplayed} />
                    </td>
                    {!isDashboard && (
                        <td className="px-6 whitespace-nowrap">
                            <div className="relative">
                                <button
                                    className="flex items-center bg-transparent hover:bg-zinc-50 text-gray-500 rounded-full p-1 focus:outline-none"
                                    onClick={toggleDropdown}
                                >
                                    <img className="w-4" src={`/icons/ellipsis-solid.svg`} alt="Options" />
                                </button>

                                {/* Dropdown content */}
                                {showDropdown && (
                                    <div className="absolute right-0 mt-2 py-2 w-48 bg-white rounded-md shadow-lg z-10">
                                        <a className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100" onClick={handlePreviewClick}>
                                            See Preview
                                        </a>
                                        <a className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100" onClick={() => handleToggleDisplay(id, isDisplayed ? 0 : 1)}>
                                            {isDisplayed ? "Don't Display News" : "Display News"}
                                        </a>
                                        <a className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100" onClick={() => navigate(`/admin/edit-news/${id}`)}>
                                            Edit
                                        </a>
                                        <a

                                            className="block px-4 py-2 text-sm text-red-600 hover:bg-red-100 hover:text-red-900"
                                            onClick={() => setShowConfirmation(true)}
                                        >
                                            Delete
                                        </a>
                                    </div>
                                )}

                                {/* Confirmation popup */}
                                {showConfirmation && (
                                    <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
                                        <div className="bg-white p-4 rounded shadow-md">
                                            <p className="mb-4">{deleteResponse}</p>
                                            <div className="flex justify-end">
                                                {!isResponseSuccess && (
                                                    <button
                                                        className="mr-2 px-4 py-2 bg-red-600 text-white rounded hover:bg-red-700"
                                                        onClick={() => {
                                                            handleDelete();
                                                            handleConfirmationClose();
                                                        }}
                                                    >
                                                        Delete
                                                    </button>
                                                )}

                                                <button
                                                    className="px-4 py-2 bg-gray-200 rounded hover:bg-gray-400"
                                                    onClick={handleConfirmationClose}
                                                >
                                                    {isResponseSuccess ? 'Close' : 'Cancel'}
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </td>
                    )}

                </tr>
            </>
        )
    }

    return (
        <table className="min-w-full">
            <thead>
                <tr>
                    <th scope="col" className="py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider w-14">
                    </th>
                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider w-2/3">
                        TITLE
                    </th>
                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider">
                        CREATED
                    </th>
                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider">
                        TRANSLATED
                    </th>
                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider">
                        DISPLAYED
                    </th>
                    {!isDashboard && (
                        <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider">
                            ACTIONS
                        </th>
                    )}

                </tr>
            </thead>
            <tbody className="bg-white">
                {news.map((data) => (
                    <Rows {...data} handlePreview={handlePreview} />
                ))}
            </tbody>
        </table>
    )
}