import React, { useEffect, useState } from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";

import { useTranslation } from "react-i18next";

import { formatDate } from "../utils/format";
import { getLocalizedHref } from "../utils/localization";
import LoadingComponent from "../components/Loading";

import tempNewsDataId from "../uploads/temp-news.id.json";
import tempNewsDataEn from "../uploads/temp-news.en.json";

export default function News() {
    const { i18n } = useTranslation();

    const [newsData, setNewsData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [lastEntry, setLastEntry] = useState({})

    useEffect(() => {
        const fetchNews = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_API}/api/news?isDisplayed=1`);
                if (response.ok) {
                    const data = await response.json();
                    data.sort((a, b) => new Date(b.date) - new Date(a.date));
                    setLastEntry(data[0]);
                    setNewsData(data.slice(1));
                } else {
                    setNewsData(i18n.language === 'en' ? tempNewsDataEn : tempNewsDataId);
                    console.error('Failed to fetch news:', response.statusText);
                }
            } catch (error) {
                setNewsData(i18n.language === 'en' ? tempNewsDataEn : tempNewsDataId);
                console.error('Error fetching news:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchNews();
    }, []);

    if (loading) {
        return (<LoadingComponent />)
    }

    return (
        <>
            <Navbar />
            <div className="pt-36 flex flex-col justify-center">
                {/* Displaying the last entry */}
                {newsData.length > 0 && (
                    <a
                        className="flex flex-row px-4 md:px-20 mx-4 lg:mx-30 xl:mx-36 mb-8 gap-4 h-48 items-center mb-12"
                        href={getLocalizedHref(`/news/${lastEntry.slug}`)}
                    >
                        <img
                            className="object-cover w-40 h-40 sm:w-80 sm:h-48 rounded-md"
                            src={`${process.env.REACT_APP_API}/${lastEntry.image}`}
                            alt={i18n.language === 'en' ? lastEntry.titleEN : lastEntry.titleID}
                        />
                        <div className="flex flex-col items-start justify-center gap-1">
                            <span className="text-sm sm:text-md font-light">
                                {formatDate(lastEntry.date)}
                            </span>
                            <span className="sm:text-xl font-semibold">
                                {i18n.language === 'en' ? (
                                    lastEntry.titleEN.length > 48
                                        ? `${lastEntry.titleEN.substring(0, 48)}...`
                                        : lastEntry.titleEN
                                ) : (
                                    lastEntry.titleID.length > 48
                                        ? `${lastEntry.titleID.substring(0, 48)}...`
                                        : lastEntry.titleID
                                )}
                            </span>
                            <span className="text-sm sm:text-md font-light text-justify" dangerouslySetInnerHTML={{
                                __html: i18n.language === 'en' ?
                                    (lastEntry.articleEN.length > 120 ?
                                        `${lastEntry.articleEN.substring(0, 120)}...` :
                                        lastEntry.articleEN) :
                                    (lastEntry.articleID.length > 120 ?
                                        `${lastEntry.articleID.substring(0, 120)}...` :
                                        lastEntry.articleID)
                            }} />
                            <span className="text-sm sm:text-md text-red-700">
                                {lastEntry.tag.split('-').map((word) => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')}
                            </span>
                        </div>
                    </a>
                )}

                {/* Displaying all news entries */}
                <div className="px-4 my-12 md:px-20 mx-4 lg:mx-30 xl:mx-36 grid grid-cols-2 lg:grid-cols-3 gap-4">
                    {newsData.map((news, index) => (
                        <a
                            className="flex flex-col w-36 sm:w-64"
                            key={index}
                            href={getLocalizedHref(`/news/${news.slug}`)}
                        >
                            <img
                                className="object-cover w-48 h-48 sm:w-64 sm:h-64 rounded-md mb-4"
                                src={`${process.env.REACT_APP_API}/${news.image}`}
                                alt={i18n.language === 'en' ? news.titleEN : news.titleID}
                            />
                            <span className="font-light text-sm sm:text-md">
                                {formatDate(news.date)}
                            </span>
                            <span className="sm:text-xl font-semibold">
                                {i18n.language === 'en' ? (
                                    news.titleEN.length > 40 ?
                                        `${news.titleEN.substring(0, 48)}...` :
                                        news.titleEN
                                ) : (
                                    news.titleID.length > 40 ?
                                        `${news.titleID.substring(0, 48)}...` :
                                        news.titleID
                                )}
                            </span>
                            <span className="font-light text-justify text-sm sm:text-md" dangerouslySetInnerHTML={{
                                __html: i18n.language === 'en' ?
                                    (news.articleEN.length > 96 ?
                                        `${news.articleEN.substring(0, 96)}...` :
                                        news.articleEN) :
                                    (news.articleID.length > 96 ?
                                        `${news.articleID.substring(0, 96)}...` :
                                        news.articleID)
                            }} />
                            <span className="text-red-700 text-sm sm:text-md">
                                {news.tag.split('-').map((word) => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')}
                            </span>
                        </a>
                    ))}

                </div>
            </div>
            <Footer />
        </>
    );
}
