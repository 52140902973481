import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";

export default function Sidebar() {
    const navigate = useNavigate();

    const handleLogout = () => {
        // Clear cookies
        Cookies.remove("token");
        // Redirect user to login page
        navigate("/admin/login");
    };

    const [isNewsExpanded, setIsNewsExpanded] = useState(true);

    return (
        <div className="bg-white text-white h-screen w-80 flex flex-col text-stone-900 mr-5">
            <div className="p-4 text-lg font-semibold text-gray-700">Persani Admin <br />Dashboard</div>
            <div className="flex-grow">
                <ul className="py-4">
                    <li className="px-4 py-2 text-gray-800 hover:bg-sky-200 hover:text-gray-800 mx-3 rounded-3xl cursor-pointer opacity-50 hover:opacity-100 flex flex-row items-center" onClick={() => navigate('/admin/dashboard')}>
                        <img
                            className="w-4 h-4 mr-2"
                            src="/icons/house.svg"
                            alt="House"
                        />
                        Overview
                    </li>
                    <li className="px-3 py-2">
                        <div className="px-4 py-2 text-gray-800 hover:bg-sky-200 hover:text-gray-800 rounded-3xl cursor-pointer opacity-50 hover:opacity-100 flex flex-row items-center" onClick={() => setIsNewsExpanded(!isNewsExpanded)}>
                            <img
                                className={`w-4 h-4 mr-2 ${isNewsExpanded ? "rotate-180" : ""}`}
                                src="/icons/angle-down.svg"
                                alt="Arrow"
                            />
                            News
                        </div>
                        {isNewsExpanded && (
                            <ul>
                                <li className="px-4 py-2 text-gray-800 hover:bg-sky-200 hover:text-gray-800 rounded-3xl cursor-pointer opacity-50 hover:opacity-100 flex flex-row items-center"
                                    onClick={() => navigate('/admin/news')}>
                                    <img
                                        className="w-4 h-4 mr-2"
                                        src="/icons/newspaper.svg"
                                        alt="Newspaper"
                                    />
                                    All News
                                </li>
                                <li className="px-4 py-2 text-gray-800 hover:bg-sky-200 hover:text-gray-800 rounded-3xl cursor-pointer opacity-50 hover:opacity-100 flex flex-row items-center" onClick={() => navigate('/admin/create-news')}>
                                    <img
                                        className="w-4 h-4 mr-2"
                                        src="/icons/pen.svg"
                                        alt="Pen"
                                    />
                                    Create News
                                </li>
                            </ul>
                        )}

                    </li>
                </ul>
            </div>
            <div>
                <ul className="pb-8">
                    <li className="px-4 py-2 text-gray-800 hover:bg-sky-200 hover:text-gray-800 mx-3 rounded-3xl cursor-pointer opacity-50 hover:opacity-100 flex flex-row items-center" onClick={handleLogout}>
                        <img
                            className="w-4 h-4 mr-2"
                            src="/icons/right-from-bracket.svg"
                            alt="Instagram"
                        />
                        Logout
                    </li>
                </ul>
            </div>
        </div>
    );
}
