import React, { useState } from 'react';
import { formatDate, formatTag } from '../../utils/format';
import DOMPurify from 'dompurify';

const NewsPreview = ({ isDisplay = false, newsData, onBack, onSubmit, onDisplayChange, isCreate }) => {
    const [language, setLanguage] = useState('ID'); // 'ID' for Indonesian, 'EN' for English
    const [isDisplayed, setIsDisplayed] = useState(true);

    const sanitizedContent = DOMPurify.sanitize(language === 'ID' ? newsData?.articleID : newsData?.articleEN);

    const handleDisplayChange = (e) => {
        const isChecked = e.target.checked;
        setIsDisplayed(isChecked);
        onDisplayChange(isChecked);
    };

    return (
        <div>
            <div className="bg-white rounded shadow-md p-4">
                <div className="flex justify-between mb-4">
                    {/* Back Button */}
                    <button
                        onClick={onBack}
                        className="px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700"
                    >
                        Back
                    </button>
                    {/* Language Switch Button */}
                    {newsData.translate > 0 && newsData.translate && (
                        <div className="flex">
                            <button
                                className={`px-4 py-2 rounded ${language === 'ID' ? 'bg-blue-600 text-white' : 'bg-gray-200 text-blue-600'}`}
                                onClick={() => setLanguage('ID')}
                            >
                                ID
                            </button>
                            <button
                                className={`ml-2 px-4 py-2 rounded ${language === 'EN' ? 'bg-blue-600 text-white' : 'bg-gray-200 text-blue-600'}`}
                                onClick={() => setLanguage('EN')}
                            >
                                EN
                            </button>
                        </div>
                    )}
                </div>
                {/* Image Section */}
                <div className="flex justify-center">
                    <img
                        className="w-screen h-[60vh] object-cover"
                        src={((!isCreate && newsData.image.startsWith('uploads\\')) || isDisplay) ? `${process.env.REACT_APP_API}/${newsData.image}` : (newsData.image ? URL.createObjectURL(newsData.image) : "https://via.placeholder.com/800x400.png?text=News+Image")}
                        alt="News"
                    />
                </div>
                {/* Content Section */}
                <div className="flex flex-col mx-12 md:mx-28 my-8">
                    <span className="text-sm text-red-700">{formatTag(newsData.tag)}</span>
                    <span className="text-3xl font-bold">
                        {language === 'ID' ? newsData.titleID : newsData.titleEN}
                    </span>
                    <span className="text-md font-light">{newsData.date ? formatDate(newsData.date, language) : formatDate(new Date(), language)}</span>
                    <div
                        className="mt-4 text-lg font-light text-justify"
                        style={{ whiteSpace: 'pre-line' }}
                        dangerouslySetInnerHTML={{ __html: sanitizedContent }}
                    />
                </div>
                {/* Display Checkbox */}
                {!isDisplay && (
                    <>
                        <div className="flex items-center justify-end mt-4">
                            <input
                                type="checkbox"
                                id="isDisplayed"
                                checked={isDisplayed}
                                onChange={handleDisplayChange}
                                className="mr-2"
                            />
                            <label htmlFor="isDisplayed">Display News</label>
                        </div>
                        {/* Submit Button */}
                        <div className="flex justify-end mt-4">
                            <button
                                onClick={onSubmit}
                                className="px-4 py-2 bg-green-600 text-white rounded hover:bg-green-700"
                            >
                                Submit News
                            </button>
                        </div>
                    </>
                )}

            </div>
        </div>
    );
};

export default NewsPreview;
