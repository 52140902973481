import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer";
import NewsCarousel from "../../components/NewsCarousel";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { getLocalizedHref } from "../../utils/localization";
import { formatDate } from "../../utils/format";

import { useParams } from 'react-router-dom';

const Page = () => {
    const { slug } = useParams();
    const { i18n } = useTranslation();

    const [article, setArticle] = useState(null);

    useEffect(() => {
        const fetchArticle = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_API}/api/news/slug/${slug}`);
                if (response.ok) {
                    const data = await response.json();
                    setArticle(data);
                } else {
                    console.error('Failed to fetch article:', response.statusText);
                }
            } catch (error) {
                console.error('Error fetching article:', error);
            }
        };

        fetchArticle();
    }, [slug]);

    return (
        <>
            <Navbar />
            {article && (
                <img
                    className="w-screen h-[80vh] object-cover"
                    src={`${process.env.REACT_APP_API}/${article.image}`}
                    alt={article.title}
                />
            )}
            {article && (
                <div className="flex flex-col mx-12 md:mx-40 my-8">
                    <span className="text-sm text-red-700">{article.tag.split('-').map((word) => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')}</span>
                    <span className="text-3xl font-bold">{i18n.language === 'en' ? article.titleEN : article.titleID}</span>
                    <span className="text-md font-light">
                        {formatDate(article.date)}
                    </span>
                    <p
                        className="mt-4 text-lg font-light text-justify"
                        style={{ whiteSpace: "pre-line" }}
                        dangerouslySetInnerHTML={{ __html: i18n.language === 'en' ? article.articleEN : article.articleID }}
                    />
                </div>
            )}

            <hr />

            <section id="news" className="px-4 md:px-20 mx-4 lg:mx-36 my-8">
                <div className="flex justify-between">
                    <h2 className="text-xl">
                        <b>Latest News</b>
                    </h2>
                    <a
                        className="text-red-700"
                        href={getLocalizedHref("/news")}
                    >
                        <b>See All</b>&ensp;
                        <i className="text-sm fa-solid fa-chevron-right"></i>
                    </a>
                </div>
                <NewsCarousel />
            </section>
            <Footer />
        </>
    );
};

export default Page;