import React, { useState } from "react";

// import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
// import {faChevronDown} from "@fortawesome/free-solid-svg-icons";

const FAQ = () => {
    const [expanded, setExpanded] = useState([]);

    const toggleAnswer = (index) => {
        setExpanded((prevExpanded) => {
            const newExpanded = [...prevExpanded];
            newExpanded[index] = !newExpanded[index];
            return newExpanded;
        });
    };

    var exampleFaq = [
        {
            question: "Apa Itu Persani?",
            answer: "Persani, atau Persatuan Senam Indonesia, adalah organisasi olahraga yang menaungi olahraga senam di Indonesia.",
            link: "",
        },
    ];
    return (
        <>
            <div className="mt-4">
                {exampleFaq.map((faq, index) => (
                    <div
                        className="border border-slate-400 rounded-md p-4 mb-2 hover:cursor-pointer"
                        key={index}
                    >
                        <div
                            className="flex flex-row justify-between items-center"
                            onClick={() => toggleAnswer(index)}
                        >
                            {faq.question}
                            <i className={`fa-solid fa-chevron-down transition-transform transform duration-300 ${expanded[index] && "rotate-180"}`}></i>
                        </div>
                        {expanded[index] && <p>{faq.answer}</p>}
                    </div>
                ))}
            </div>
        </>
    );
};

export default FAQ;
