
const Footer = () => {
    return (
        <>
            <div className="bg-black text-white p-8 flex flex-col">
                <div className="flex flex-col text-right md:text-left md:flex-row md:justify-between md:items-center">
                    <div className="flex flex-col mb-4">
                        <h2 className="text-sm font-semibold">CONTACT US</h2>
                        <span>+62 21234 56789</span>
                        <span>+62 8586 6963 000</span>
                    </div>
                    <hr className="opacity-25" />
                    <div className="text-right md:text-right mt-4">
                        <h2 className="text-sm font-semibold">OUR HEADQUARTERS</h2>
                        <span>
                            South Quarter Tower
                            <br />
                            7th Floor, RA Kartini St
                            <br />
                            Jakarta Indonesia
                        </span>
                    </div>
                </div>

                <div className="mt-8 flex justify-center gap-2">
                    <a href="https://instagram.com/gymnastics.indonesia?igshid=NGVhN2U2NjQ0Yg==" target="_blank" rel="noreferrer"><img className="bg-white fill-white w-10 h-10 rounded-full p-1" src="/icons/instagram.svg" alt="Persani's Instagram" /></a>
                    <a href="https://www.linkedin.com/in/gymnastics-indonesia-83a872281?lipi=urn%3Ali%3Apage%3Ad_flagship3_profile_view_base_contact_details%3BiXUug8JoSrCCiv4I0QhrZQ%3D%3D" target="_blank" rel="noreferrer"><img className="bg-white fill-white w-10 h-10 rounded-full p-1" src="/icons/linkedin.svg" alt="Persani's Linkedin" /></a>
                    <a href="https://www.youtube.com/@gymnasticsindonesiachannel4654" target="_blank" rel="noreferrer"><img className="bg-white fill-white w-10 h-10 rounded-full p-1" src="/icons/youtube.svg" alt="Persani's Youtube Channel" /></a>
                </div>
            </div>
        </>
    );
};

export default Footer;
