import React from "react";

export default function PrimaryButton({ name, onClick, icon }) {

    return (
        <button onClick={onClick} className="text-white text-lg font-semibold bg-blue-500 hover:bg-blue-400 px-4 py-1 w-40 rounded flex flex-row items-center justify-center">
            {icon && (
                <img
                    className="w-4 h-4 mr-2"
                    src={`/icons/${icon}.svg`}
                    alt={icon}
                />
            )}
            {name}
        </button>
    )
}