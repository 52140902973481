import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import NewsForm from '../../components/admin/NewsForm';
import Sidebar from '../../components/admin/Sidebar';
import Title from '../../components/admin/Title';
import NewsPreview from '../../components/admin/NewsPreview';
import Cookies from "js-cookie";
import useAuthentication from '../../utils/useAuthentication';
import LoadingComponent from '../../components/Loading';

export default function EditNews() {
    const isLoading = useAuthentication();

    const navigate = useNavigate();
    const { id } = useParams(); // Get the id parameter from the URL
  
    const [showPreview, setShowPreview] = useState(false);
    const [newsData, setNewsData] = useState(null);
    const [isDisplayed, setIsDisplayed] = useState(true);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchNewsById = async (id) => {
            try {
                const response = await fetch(`${process.env.REACT_APP_API}/api/news/${id}`);
                if (response.ok) {
                    const data = await response.json();
                    setNewsData(data);
                } else {
                    console.error('Failed to fetch news:', response.statusText);
                    throw new Error('Failed to fetch news');
                }
            } catch (error) {
                console.error('Error fetching news:', error.message);
                throw new Error('Error fetching news');
            } finally {
                setLoading(false);
            }
        };

        if (id) {
            fetchNewsById(id);
        } else {
            setLoading(false);
        }
    }, [id]);

    const handleFormSubmit = (formData) => {
        setNewsData(formData);
        setShowPreview(true);
    };

    const handleDisplayChange = (isChecked) => {
        setIsDisplayed(isChecked); // Update isDisplayed state
    };

    const handleNewsSubmit = async () => {
        const token = Cookies.get("token");
        try {
            const formData = new FormData();
            formData.append('titleID', newsData.titleID);
            formData.append('titleEN', newsData.titleEN);
            formData.append('articleID', newsData.articleID);
            formData.append('articleEN', newsData.articleEN);
            formData.append('tag', newsData.tag);
            formData.append('image', newsData.image);
            formData.append('slug', newsData.slug);
            formData.append('translate', newsData.translate ? 1 : 0);
            formData.append('isDisplayed', isDisplayed ? 1 : 0);

            const response = await fetch(`${process.env.REACT_APP_API}/api/news/${id}`, {
                method: 'PUT',
                headers: {
                    'Authorization': `Bearer ${token}`
                },
                body: formData,
            });

            if (response.ok) {
                console.log('News edited successfully');
                setShowPreview(false);
                setNewsData(null); // Clear the form data
                navigate('/admin/dashboard');
            } else {
                console.error('Failed to edit news');
            }
        } catch (error) {
            console.error('Error editing news:', error);
        }
    };

    if (isLoading){
        return <LoadingComponent />
    }

    return (
        <div className="bg-blue-50">
            <div className="flex flex-row">
                <Sidebar />
                <div className="m-4 mr-8 w-full">
                    <Title title={showPreview ? 'News Preview' : 'Edit News'} />
                    <div className="bg-white my-4 rounded">
                        {!loading && !showPreview && newsData && (
                            <NewsForm onSubmit={handleFormSubmit} initialData={newsData} />
                        )}
                        {!loading && showPreview && newsData && (
                            <NewsPreview
                                newsData={newsData}
                                onBack={() => setShowPreview(false)}
                                onSubmit={handleNewsSubmit}
                                onDisplayChange={handleDisplayChange}
                            />
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}
