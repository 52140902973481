import React, { useState } from "react";
import IndicatorButtons from "./buttons/IndicatorButtons";

const Gallery = () => {
    const [currentPage, setCurrentPage] = useState(1);

    const galleryExample = [
        {
            link: "https://www.youtube.com/embed/TZxJisWdayc?si=GzjN4CHJUGlrlo4j",
            type: "video",
            title: "HIGHLIGHT ASIAN PARKOUR TOUR 2023",
        },
        {
            link: "https://www.youtube.com/embed/4cLa0oktH48?si=dQzL5alIBouyDkqX",
            type: "video",
            title: "HIGHLIGHT ASIAN PARKOUR TOUR 2023",
        },
        {
            link: "https://lh3.googleusercontent.com/pw/ADCreHfMfkUBH4bCTKn4p_Yqz_SSq4LApvuymYaCQQFOw2wep_4GL2XUhTZSmKs7-PIIJaYHcsAVRE5vIvnea6YuwcG5x9keLpnjRnzAsBExw8PyAerX10xXc5hOVePYiOb8nxXD4paoipoI-7vJ8uQ48Zcq=w1380-h648-s-no",
            type: "image",
            title: "TC Parkour Day 1",
            href: "https://photos.app.goo.gl/74DjvPHuXv8nB6JW8",
        },
        {
            link: "https://lh3.googleusercontent.com/pw/ADCreHeswHYrbVMnh8zbuFULdOUsyfufaXo-aNMg-bnY2fag8Ktl_DK7krkcTpQWDS7IN_q4KmmnUpVcCUZpfhMQ-JtQy_6xl0MmAloMHubT3zFs-dE7lTLmrV9a4fa8eCxA0BIR6LTSBkxK5ozqHYYEO9eC=w1380-h920-s-no?authuser=0",
            type: "image",
            title: "Pelatihan Pelatih Artistik Tingkat Dasar",
            href: "https://photos.app.goo.gl/GjubCZ5urYKVxBNX7",
        },
        {
            link: "https://lh3.googleusercontent.com/pw/ADCreHczUtBIAcBArsBjDN-6ITuCdaPcwRydJoNnHjIINzupGrvl-mOz_xCdqAIhzkQIteMZFfxLnpL7ffOGkAB57yP_fzItsbrl8_KDaOOs3HNHUyv8ZmHtjgu7cna7n8XK9bmahO0lceRFz1_ooI4I9TzI=w1410-h959-s-no?authuser=0",
            type: "image",
            title: "Opening NGC 2023",
            href: "https://photos.app.goo.gl/RphY2F5Ymxrnm3P77",
        },
        {
            link: "https://lh3.googleusercontent.com/pw/ADCreHdk6UA6Cxsfsnk1oUPRazRHMRotbwV_IKiGIDL20tC44rGAFP7gJ9IHSfxUCYYIBwf_YyL7_BDHw0Go2e5HiB44KSpQe1K1vcNuopJ7iaShhEOdji9F1bO6gT8NM_nF-7INs5Ag3VL05oejY2dJGMxa=w1439-h959-s-no?authuser=0",
            type: "image",
            title: "Panitia NGC 2023",
            href: "https://photos.app.goo.gl/BD3wDpQDsqfappCa7",
        },
        {
            link: "https://lh3.googleusercontent.com/pw/ADCreHezF4HusDo08f7Vmb7_khUp6f8_Kz1xxqw8gQW3XMgZ2qcY-RcieTj250LGuI5Yb8EKCdJAYC_UUqGs1Zup2_ACm9bpiMio8m2PPK1pwvJiF2JqdkiruLG7im_gQKXv0nITpozzNym1UoYp-rkwE0CV=w1439-h959-s-no?authuser=0",
            type: "image",
            title: "AGAC 2023, Singapore - Rifda All Round - Day 1",
            href: "https://photos.app.goo.gl/GLRQtW8kjQV5LziL8",
        },
        {
            link: "https://lh3.googleusercontent.com/pw/ADCreHeB1_1c_5tsAKmwj4PG67l5AVyMZ1kPZZgU7R18YGDh0gBZg14lvKJr6KLFB5ujFTYDIb3TvOVXITsugK_8iPVuIlUO9C5t7yJ6cGOx68ADNvBaZssMX_dviVC0S9F6iX3LhCe-HQz7Od1gDWSbo4bB=w1439-h959-s-no?authuser=0",
            type: "image",
            title: "AGAC 2023, Singapore - Ameera - Day 1",
            href: "https://photos.app.goo.gl/BpHriLrpmpHzesAPA",
        },
        {
            link: "https://lh3.googleusercontent.com/pw/ADCreHciz5WZ8Ui-ZeRK-rw2CXcPslCLG6S31QsdVNK8gi47gpfFJIk9OEtzSmFbPT2ZbSHxqGV91wK-TDFRuNWJ7-mZQCDeqi6MQHuTQmug6BnFb_7QnQWpXqFnGmj2bhXrkUg5TCN11RwxMF4gSDx1G4cN=w1439-h959-s-no?authuser=0",
            type: "image",
            title: "AGAC 2023, Singapore - Salsabila - Day 1",
            href: "https://photos.app.goo.gl/mHTUsXg6vr7U9cEz6",
        },
        {
            link: "https://lh3.googleusercontent.com/pw/ADCreHc9Yv_KzOFz-drBXy7rV37CSghaDxEaG0LzZU9URraN5anB5uexLO_hx1V8gycrPBf910lAbsaPnpqZD5bGwc33zFSWCSV7_AqtBss_vZlUE40ChghRCjWY5Yve6KgEpC6TrdIq5em-ld3h6JSocQ3Y=w1380-h920-s-no?authuser=0",
            type: "image",
            title: "AGAC 2023, Singapore - Larasati - Day 1",
            href: "https://photos.app.goo.gl/h5Ckvyk8SNMwtv329",
        },
    ];

    const thisPage = galleryExample.slice(currentPage - 1, currentPage);

    const handleClick = (page) => {
        setCurrentPage(page);
    };

    return (
        <>
            <div className="mt-4">
                {thisPage.map((media, index) => (
                    <div key={index}>
                        {media.type === "video" ? (
                            <iframe
                                className="w-full h-96"
                                src={media.link}
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                allowFullScreen
                                title={media.title}
                            ></iframe>
                        ) : (
                            <>
                                <a href={media.href} target="_blank" rel="noreferrer">
                                    <div
                                        className="w-full h-96 object-cover shadow-[inset_0_0_0_2000px_rgba(0,0,0,0.3)] hover:shadow-[inset_0_0_0_2000px_rgba(0,0,0,0)] text-white relative"
                                        style={{
                                            backgroundImage: `url(${media.link})`,
                                            backgroundSize: "cover",
                                            backgroundPosition: "center",
                                        }}
                                    >
                                        <span className="absolute bottom-0 left-0 p-4 font-semibold drop-shadow-sm">
                                            {media.title}
                                        </span>
                                    </div>
                                </a>

                                {/* <img
                                    src={media.link}
                                    className="w-full h-80 object-cover"
                                    alt={media.title}
                                /> */}
                            </>
                        )}
                    </div>
                ))}
            </div>
            {/* Indicator Buttons */}
            <IndicatorButtons
                length={galleryExample.length}
                onClick={handleClick}
                currentSlide={currentPage}
            />
            {/* <div className="flex mt-4 justify-center">
                {Array.from({
                    length: galleryExample.length,
                }).map((_, index) => (
                    <button
                        key={index}
                        onClick={() => handleClick(index + 1)}
                        className={`h-4 bg-slate-200 rounded-full focus:outline-none mr-1 ${
                            index + 1 === currentPage ? "w-12" : "w-4"
                        }`}
                    ></button>
                ))}
            </div> */}
        </>
    );
};
export default Gallery;
