import { useNavigate } from "react-router-dom";
import PrimaryButton from "../../components/admin/PrimaryButton";
import Sidebar from "../../components/admin/Sidebar";
import NewsTable from "../../components/admin/Table/NewsTable";
import Title from "../../components/admin/Title";
import { useEffect, useState } from "react";
import Pagination from "../../components/admin/Pagination";
import NewsPreview from "../../components/admin/NewsPreview";
import useAuthentication from "../../utils/useAuthentication";
import LoadingComponent from "../../components/Loading";

export default function AllNews() {
  const loading = useAuthentication();

  const navigate = useNavigate();

  const [news, setNews] = useState([]);

  const [currentPage, setCurrentPage] = useState(1);
  const [limit, setLimit] = useState(10);

  const [previewData, setPreviewData] = useState(null);
  const [searchQuery, setSearchQuery] = useState(""); // Add search state

  useEffect(() => {
    const fetchNews = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API}/api/news`);
        if (response.ok) {
          const data = await response.json();
          data.sort((a, b) => new Date(b.date) - new Date(a.date));
          setNews(data);
        } else {
          console.error("Failed to fetch news");
        }
      } catch (error) {
        console.error("Error fetching news:", error);
      }
    };

    fetchNews();
  }, [currentPage, limit]);

  const handleLimitChange = (newLimit) => {
    setLimit(newLimit);
    setCurrentPage(1); // Reset to first page when limit changes
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value); // Update search query
  };

  // Filter news based on search query
  const filteredNews = news.filter((item) =>
    item.titleID.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const startIndex = (currentPage - 1) * limit;
  const paginatedNews = filteredNews.slice(startIndex, startIndex + limit);
  const currentData = paginatedNews.length;
  const totalData = filteredNews.length;

  if (loading){
    return <LoadingComponent />
  }

  return (
    <>
      <div className="bg-blue-50 w-100">
        <div className="flex flex-row">
          <Sidebar />
          <div className="m-4 mr-8 w-full">
            {!previewData ? (
              <>
                <Title title="All News" />
                <div className="bg-white my-4 rounded">
                  <div className="flex justify-between border-b-2 border-sky-100 px-4 pt-4 pb-2">
                    <input
                      type="text"
                      placeholder="Search by Title"
                      value={searchQuery}
                      onChange={handleSearchChange}
                      className="rounded-full border border-gray-300 p-2 rounded focus:outline-none focus:ring focus:border-blue-500"
                    />
                    <PrimaryButton
                      name="Create News"
                      icon=""
                      onClick={() => navigate("/admin/create-news")}
                    />
                  </div>
                  {/* bod */}
                  <div className="container py-4 flex flex-col gap-4 justify-between items-end">
                    <Pagination
                      currentData={currentData}
                      totalData={totalData}
                      onLimitChange={handleLimitChange}
                    />
                    <NewsTable
                      news={paginatedNews}
                      handlePreview={(data) => setPreviewData(data)}
                    />
                  </div>
                </div>
              </>
            ) : (
              <>
                <Title title="News Preview" />
                <div className="my-4">
                  <NewsPreview
                    newsData={previewData}
                    onBack={() => setPreviewData(null)}
                    isDisplay={true}
                  />
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
