import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useLocation } from "react-router";
// import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
// import {faX, faBars} from "@fortawesome/free-solid-svg-icons";

import { getLocalizedHref } from "../utils/localization";

const Navbar = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { i18n } = useTranslation();
    const [isMenuOpen, setMenuOpen] = useState(false);
    const [isTranslationOpen, setTranslationOpen] = useState(false);

    const { t } = useTranslation();
    const currentLocale = i18n ? i18n.language : "id";

    // to open burger menu
    const toggleMenu = () => {
        setMenuOpen(!isMenuOpen);
    };

    // to open translation dropdown (on big screens)
    const toggleTranslation = () => {
        setTranslationOpen(!isTranslationOpen);
    };

    // function to switch between bahasa and english
    const changeLanguage = (lang) => {
        let newPath = location.pathname; // Get the current pathname

        newPath = newPath.replace(/^\/(en|id)\//, `/${lang}/`);

        navigate(newPath);
        window.location.reload();

        setTranslationOpen(false);
    };

    const handleNavigation = (path) => {
        const localizedPath = getLocalizedHref(path);
        navigate(localizedPath);
    };

    const pathnameWithoutLangPrefix = location.pathname.substring(3);

    return (
        <>
            <nav className="bg-white px-15 lg:px-20 py-6 md:py-4 font-light text-black fixed w-full z-50">
                <div className="container mx-auto flex items-center justify-between">
                    {/* Navigation Links */}
                    <img src="/logo-persani.png" className="hidden md:flex h-8 w-20" alt="Logo of Persani" />

                    <div className="hidden md:flex gap-6 lg:gap-8 text-left">
                        <span
                            onClick={() => handleNavigation("/")}
                            className={`hover:font-semibold hover:cursor-pointer w-fit ${pathnameWithoutLangPrefix === "/" ? "font-semibold" : ""
                                }`}
                        >
                            {t("navbar.home")}
                        </span>
                        <span
                            onClick={() => handleNavigation("/about-us")}
                            className={`hover:font-semibold hover:cursor-pointer w-fit ${pathnameWithoutLangPrefix === "/about-us"
                                ? "font-semibold"
                                : ""
                                }`}
                        >
                            {t("navbar.about-us")}
                        </span>
                        <span
                            onClick={() => handleNavigation("/news")}
                            className={`hover:font-semibold hover:cursor-pointer w-fit ${pathnameWithoutLangPrefix === "/news"
                                ? "font-semibold"
                                : ""
                                }`}
                        >
                            {t("navbar.news")}
                        </span>
                        <span
                            onClick={() => handleNavigation("/disciplines")}
                            className={`hover:font-semibold hover:cursor-pointer w-fit ${pathnameWithoutLangPrefix === "/disciplines"
                                ? "font-semibold"
                                : ""
                                }`}
                        >
                            {t("navbar.disciplines")}
                        </span>
                        {/* <a href="#" className="hover:font-semibold w-fit">
                            {t("navbar.athletes")}
                        </a>
                        <a href="#" className="hover:font-semibold w-fit">
                            {t("navbar.calendar")}
                        </a>

                        <a href="#" className="hover:font-semibold w-fit">
                            {t("navbar.shop")}
                        </a> */}
                    </div>

                    {/* Translation */}
                    <div className="hidden md:flex relative pr-2 w-20">
                        <button
                            className="hover:font-semibold"
                            onClick={toggleTranslation}
                        >
                            {currentLocale === "en" ? "EN" : "ID"}
                        </button>
                        {isTranslationOpen && (
                            <div className="absolute top-8 right-0 bg-white w-20 p-2 text-right flex flex-col z-50">
                                <button
                                    className="py-2 text-right"
                                    onClick={() => changeLanguage("en")}
                                >
                                    English
                                </button>
                                <button
                                    className="py-2 text-right"
                                    onClick={() => changeLanguage("id")}
                                >
                                    Bahasa
                                </button>
                            </div>
                        )}
                    </div>

                    {/* Burger Menu for Mobile (Visible on Small Screens) */}
                    <div className="md:hidden absolute right-4 text-2xl">
                        <button onClick={toggleMenu} className="">
                            <i className={`fa-solid ${isMenuOpen ? "fa-x" : "fa-bars"}`}></i>
                        </button>
                    </div>
                </div>
                {isMenuOpen && (
                    <div className="md:hidden fixed bg-white px-4 pb-2 mt-4 w-full h-full z-50 flex flex-col items-end justify-center gap-8 text-2xl right-0 font-light">
                        <span
                            onClick={() => handleNavigation("/")}
                            className="hover:cursor-pointer w-fit"
                        >
                            {t("navbar.home")}
                        </span>
                        <span
                            onClick={() => handleNavigation("/about-us")}
                            className="hover:cursor-pointer w-fit"
                        >
                            {t("navbar.about-us")}
                        </span>
                        <span
                            onClick={() => handleNavigation("/news")}
                            className="hover:cursor-pointer w-fit"
                        >
                            {t("navbar.news")}
                        </span>
                        <span
                            onClick={() => handleNavigation("/disciplines")}
                            className="hover:cursor-pointer w-fit"
                        >
                            {t("navbar.disciplines")}
                        </span>
                        {/* <a href="#" className="w-fit">
                            {t("navbar.athletes")}
                        </a>
                        <a href="#" className="w-fit">
                            {t("navbar.calendar")}
                        </a>
                        <a href="#" className="w-fit">
                            {t("navbar.shop")}
                        </a> */}

                        <button
                            onClick={() => {
                                currentLocale === "en"
                                    ? changeLanguage("id")
                                    : changeLanguage("en");
                            }}
                        >
                            {currentLocale === "en" ? (
                                <span>
                                    <b className="text-red-700">EN</b>/ID
                                </span>
                            ) : (
                                <span>
                                    EN/<b className="text-red-700">ID</b>
                                </span>
                            )}
                        </button>
                    </div>
                )}
            </nav>
            {/* Mobile Menu (Hidden on Larger Screens) */}
        </>
    );
};

export default Navbar;
