import React, { useEffect, useState } from "react";
// import {faChevronRight, faChevronLeft} from "@fortawesome/free-solid-svg-icons";

import { useTranslation } from "react-i18next";

const Carousel = () => {
  const { t, i18n } = useTranslation();

  const [currentSlide, setCurrentSlide] = useState(0);
  const [slides, setSlides] = useState([]);

  useEffect(() => {
    const fetchNews = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API}/api/news?isDisplayed=1`
        );
        if (response.ok) {
          const data = await response.json();
          setSlides(data.slice(0, 4));
        } else {
          console.error("Failed to fetch news:", response.statusText);
        }
      } catch (error) {
        console.error("Error fetching news:", error);
      }
    };

    fetchNews();
  }, []);

  const handleIndicatorClick = (index) => {
    setCurrentSlide(index);
  };

  const handlePrevSlide = () => {
    setCurrentSlide((prevSlide) =>
      prevSlide === 0 ? slides.length - 1 : prevSlide - 1
    );
  };

  const handleNextSlide = () => {
    setCurrentSlide((prevSlide) =>
      prevSlide === slides.length - 1 ? 0 : prevSlide + 1
    );
  };

  return (
    <div className="relative min-h-[35rem] h-[80vh] md:h-[100vh] overflow-hidden text-white">
      <div className="flex transition transition-all duration-300 ease-in-out h-full">
        {slides.map((slide, index) => (
          <div
            key={index}
            className={`w-full h-full transition-opacity flex ${
              index === currentSlide ? "opacity-100" : "opacity-0 hidden"
            }`}
            style={{
              backgroundImage: `url(${
                process.env.REACT_APP_API
              }/${slide.image.replace(/\\/g, "/")})`,
              boxShadow: "inset 0 0 0 2000px rgba(0, 0, 0, 0.7)",
              backgroundSize: "cover",
              backgroundPosition: "center",
              width: "100%",
              height: "100%",
            }}
          >
            <div className="md:w-1/2"></div>
            <div className="flex flex-col items-right w-full md:w-1/2 text-right pt-20 px-8 my-auto">
              <h1 className="text-4xl font-bold serif-font">
                {i18n.language === "id" ? slide.titleID : slide.titleEN}
              </h1>
              <hr className="border-2 border-red-800 w-1/3 ml-auto" />
              {/* <p
                className="w-full text-md md:text-lg"
                dangerouslySetInnerHTML={{
                  __html:
                    i18n.language === "id"
                      ? slide.articleID.length > 96
                        ? `${slide.articleID.substring(0, 96)}...`
                        : slide.articleID
                      : slide.articleEN.length > 96
                      ? `${slide.articleEN.substring(0, 96)}...`
                      : slide.articleEN,
                }}
              ></p> */}
              <a
                href={`/${i18n.language}/news/${slide.slug}`}
                className="mt-6 px-4 py-2 rounded-full text-red-700 font-semibold"
              >
                {t("button.more-info")} &ensp;
                <i className="fa-solid fa-chevron-right"></i>
              </a>
            </div>
          </div>
        ))}
      </div>

      {/* Previous and Next Buttons */}
      <button
        onClick={handlePrevSlide}
        className="absolute bottom-8 right-[5rem] transform -translate-y-1/2 text-2xl opacity-50 hover:opacity-100 hover:cursor-pointer rounded-full border border-solid px-2"
      >
        <i className="fa-solid fa-chevron-left"></i>
      </button>
      <button
        onClick={handleNextSlide}
        className="absolute bottom-8 right-[2rem] transform -translate-y-1/2 text-2xl opacity-50 hover:opacity-100 hover:cursor-pointer rounded-full border border-solid px-2"
      >
        <i className="fa-solid fa-chevron-right"></i>
      </button>

      {/* Indicator Buttons */}
      <div className="absolute top-[6rem] lg:top-[8rem] bottom-4 right-[2rem] flex flex-col space-y-1">
        {slides.map((_, index) => (
          <button
            key={index}
            onClick={() => handleIndicatorClick(index)}
            className={`h-4 opacity-50 rounded-full focus:outline-none w-4 ${
              index === currentSlide ? "bg-rose-400" : "bg-slate-200"
            }`}
          ></button>
        ))}
      </div>
    </div>
  );
};

export default Carousel;
