import React, { useState } from 'react';

const Pagination = ({ currentData, totalData, onLimitChange }) => {
    const [limit, setLimit] = useState(10);

    const handleLimitChange = (e) => {
        const newLimit = parseInt(e.target.value, 10);
        setLimit(newLimit);
        onLimitChange(newLimit);
    };

    return (
        <div className="flex justify-between items-center mr-6">
            <div className="flex items-center">
                <span className="mr-2 text-slate-400">Showing {currentData} out of {totalData} news | </span>
            </div>
            <div className="flex items-center">
                <span className="mr-2 text-slate-400">Limit:</span>
                <select
                    value={limit}
                    onChange={handleLimitChange}
                    className="px-2 py-1 rounded border border-gray-300 bg-white focus:outline-none focus:border-blue-500 hover:cursor-pointer"
                >
                    {[5, 10, 15, 20].map((value) => (
                        <option key={value} value={value}>
                            {value}
                        </option>
                    ))}
                </select>
            </div>

        </div>
    );
};

export default Pagination;
