// const router = useRouter();

/**
 * @description used for consistent language when user clicked to another page
 * @param {*} path 
 * @param {*} router 
 * @returns string
 */
export const getLocalizedHref = (path) => {
    const currentLocale = window.location.pathname.split('/')[1]; // Extract current locale from URL
    return currentLocale === 'en' ? `/en${path}` : `/id${path}`;
};