import React from "react";

export default function SecondaryButton({ name, onClick, icon }) {

    return (
        <button onClick={onClick} className="text-blue-500 border border-blue-400 text-lg font-semibold bg-transparent hover:bg-blue-400 hover:text-white px-4 py-1 w-40 rounded flex flex-row items-center justify-center">
            {icon && (
                <img
                    className="w-4 h-4 mr-2"
                    src={`/icons/${icon}.svg`}
                    alt={icon}
                />
            )}
            {name}
        </button>
    )
}