import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { Navigate, BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import { initReactI18next } from 'react-i18next';
import i18next from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import Home from './pages';
import Page from './pages/news/SlugPage';
import AboutUs from './pages/about-us';
import Disciplines from './pages/disciplines';
import News from './pages/news';

import Login from './pages/admin/login';
import AdminDashboard from './pages/admin/dashboard';
import AllNews from './pages/admin/news';
import CreateNews from './pages/admin/create-news';
import EditNews from './pages/admin/edit-news';

i18next
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(LanguageDetector) // enables automatic language detection
  .init({
    detection: {
      order: ['path', 'navigator'],
    },
    resources: {
      id: {
        translation: require('./locales/id/common.json')
      },
      en: {
        translation: require('./locales/en/common.json')
      },
    },
    fallbackLng: 'id', // fallback language
    debug: true, // enable debug mode
    interpolation: {
      escapeValue: false // not needed for react as it escapes by default
    }
  });

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Router>
      <Routes>
        {/* English Routes */}
        <Route path="/en/*" element={<EnglishPages />} />
        {/* Indonesian Routes */}
        <Route path="/id/*" element={<IndonesianPages />} />
        <Route path="/admin/*" element={<AdminPages />} />
        <Route path="/*" element={<Navigate to={`/id/`} replace />} />
      </Routes>
    </Router>
  </React.StrictMode>
);
// EnglishPages component to handle English routes
function EnglishPages() {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/about-us" element={<AboutUs />} />
      <Route path="/disciplines" element={<Disciplines />} />
      <Route path="/news" element={<News />} />
      <Route path="/news/:slug" element={<Page />} />
    </Routes>
  );
}

// IndonesianPages component to handle Indonesian routes
function IndonesianPages() {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/about-us" element={<AboutUs />} />
      <Route path="/disciplines" element={<Disciplines />} />
      <Route path="/news" element={<News />} />
      <Route path="/news/:slug" element={<Page />} />
    </Routes>
  );
}

function AdminPages() {
  return (
    <Routes>
      <Route path="/dashboard" element={<AdminDashboard />} />
      <Route path="/login" element={<Login />} />
      <Route path="/news" element={<AllNews />} />
      <Route path="/create-news" element={<CreateNews />} />
      <Route path="/edit-news/:id" element={<EditNews />} />

    </Routes>

  );
}
reportWebVitals();
