import React, { useState } from 'react';
import NewsForm from '../../components/admin/NewsForm';
import Sidebar from '../../components/admin/Sidebar';
import Title from '../../components/admin/Title';
import NewsPreview from '../../components/admin/NewsPreview';
import { useNavigate } from 'react-router-dom';
import Cookies from "js-cookie";
import useAuthentication from '../../utils/useAuthentication';
import LoadingComponent from '../../components/Loading';

export default function CreateNews() {
  const loading = useAuthentication();
  const navigate = useNavigate();

    const [showPreview, setShowPreview] = useState(false);
    const [newsData, setNewsData] = useState(null);
    const [isDisplayed, setIsDisplayed] = useState(true);

    const handleFormSubmit = (formData) => {
        setNewsData(formData);
        setShowPreview(true);
    };

    const handleDisplayChange = (isChecked) => {
        setIsDisplayed(isChecked); // Update isDisplayed state
    };

    const handleNewsSubmit = async () => {
        const token = Cookies.get("token");

        try {
            const formData = new FormData();
            formData.append('titleID', newsData.titleID);
            formData.append('titleEN', newsData.titleEN);
            formData.append('articleID', newsData.articleID);
            formData.append('articleEN', newsData.articleEN);
            formData.append('tag', newsData.tag);
            formData.append('image', newsData.image);
            formData.append('slug', newsData.slug);
            formData.append('translate', newsData.translate ? 1 : 0);
            formData.append('isDisplayed', isDisplayed === true ? 1 : 0);

            const response = await fetch(`${process.env.REACT_APP_API}/api/news`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`
                },
                body: formData,
            });

            if (response.ok) {
                // Handle successful response
                console.log('News submitted successfully');
                setShowPreview(false);
                setNewsData(null); // Clear the form data
                navigate('/admin/news')
            } else {
                // Handle error response
                console.error('Failed to submit news');
            }
        } catch (error) {
            console.error('Error submitting news:', error);
        }
    };

    if (loading){
        return <LoadingComponent />
    }

    return (
        <>
            <div className="bg-blue-50">
                <div className="flex flex-row">
                    <Sidebar />
                    <div className="m-4 mr-8 w-full">
                        <Title title={showPreview ? "News Preview" : "Create News"} />
                        <div className="bg-white my-4 rounded">
                            {(!showPreview) ? (
                                <NewsForm onSubmit={handleFormSubmit} initialData={newsData} />
                            ) : (
                                <NewsPreview newsData={newsData} onBack={() => setShowPreview(false)} onSubmit={handleNewsSubmit} onDisplayChange={handleDisplayChange} isCreate={true} />
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
