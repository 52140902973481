import Navbar from "../components/Navbar";
import Footer from "../components/Footer";

import { useTranslation } from "react-i18next";

export default function AboutUs() {
    const { t } = useTranslation();

    var teamInfo = [
        {
            name: "Ita Yuliati",
            title: "PRESIDENT",
            img: "/images/profiles/ita-yuliati.jpg",
            social: "https://www.instagram.com/itajuliati",
        },
        {
            name: "Sari Kencana Ayu",
            title: "SECRETARY GENERAL",
            img: "/images/profiles/sary-kencana-ayu.jpg",
            social: "https://www.instagram.com/sarikencanaayu",
        },
        {
            name: "Aang Sunadji",
            title: "VICE PRESIDENT",
            img: "/images/profiles/aang-sunadji.jpg",
            social: "https://www.instagram.com/aangsunadji",
        },
        {
            name: "Hesti Diwayanti",
            title: "BINPRES",
            img: "https://placekitten.com/1203/412",
            social: "https://www.instagram.com/hestidiw",
        },
        {
            name: "Mayke Ridwan",
            title: "FINANCE",
            img: "https://placekitten.com/1100/411",
            social: "https://www.instagram.com/maykeridwan25",
        },
        {
            name: "Irwan K",
            title: "IT & DIGITAL CREATIVE",
            img: "https://placekitten.com/1103/412",
            social: "https://www.instagram.com/ina.sportcorner",
        },
    ];

    return (
        <>
            <Navbar />
            <img
                className="w-full h-96 object-cover"
                src="/images/about-us-main.jpg"
                alt="Persani members"
            />

            <section
                id="identity"
                className="px-4 md:px-20 mx-4 lg:mx-36 my-8 flex flex-col text-justify"
            >
                <h2 className="text-xl">
                    <b>{t("heading.identity")}</b>
                </h2>
                <p>{t("about-us.identity")}</p>
            </section>

            <section
                id="history"
                className="px-4 md:px-20 mx-4 lg:mx-36 my-8 flex flex-col text-justify"
            >
                <h2 className="text-xl">
                    <b>{t("heading.history")}</b>
                </h2>
                <div style={{ whiteSpace: "pre-line" }}>
                    <p>{t("about-us.history.1")}</p>
                    <p className="font-bold">{t("about-us.history.2")}</p>
                    <p>{t("about-us.history.3")}</p>
                </div>
            </section>

            <section
                id="mission"
                className="px-4 md:px-20 mx-4 lg:mx-36 my-8 flex flex-col text-justify"
            >
                <h2 className="text-xl">
                    <b>{t("heading.mission")}</b>
                </h2>

                <ul className="list-disc ml-4">
                    {[0, 1, 2].map((index) => (
                        <li key={index}>{t(`about-us.mission.${index}`)}</li>
                    ))}
                </ul>
            </section>

            <section
                id="team"
                className="px-8 md:px-24 py-8 lg:px-56 flex flex-col bg-black mb-10 gap-4"
            >
                <h2 className="text-xl text-white">
                    <b>{t("heading.team")}</b>
                </h2>

                <div className="grid grid-cols-2 sm:grid-cols-3 gap-4">
                    {teamInfo.map((member, index) => (
                        <div className="bg-white rounded-md shadow-md text-center flex flex-col h-72 min-w-[8.5rem]" key={index}>
                            <div className="h-60 w-full mb-4 overflow-hidden rounded-t-md">
                                <img
                                    className="w-full h-full object-cover"
                                    src={member.img}
                                    alt={member.name}
                                />
                            </div>
                            <div className="flex-grow flex flex-col justify-between">
                                <div>
                                    <h3 className="font-bold text-sm sm:text-lg">
                                        {member.name}
                                    </h3>
                                    <p className="text-xs md:text-sm text-gray-500">
                                        {member.title}
                                    </p>
                                </div>
                                <div className="mt-4">
                                    <a
                                        href={member.social}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="inline-block bg-gray-200 hover:bg-gray-300 w-8 h-8 rounded-full p-1"
                                    >
                                        <img
                                            className="w-full h-full"
                                            src="/icons/instagram.svg"
                                            alt="Instagram"
                                        />
                                    </a>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </section>

            {/* info@gymnasticsindonesia.id */}
            <section
                id="contact"
                className="px-4 md:px-20 mx-4 lg:mx-36 my-12 flex flex-col items-center"
            >
                <h2 className="text-xl text-red-700 mb-4">
                    <b>Contact Us</b>
                </h2>
                <div className="flex flex-col md:flex-row justify-between gap-12 w-full">
                    <iframe
                        title="Map to Persani HQ"
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d15863.061394906194!2d106.76685131546594!3d-6.294538047252199!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e69f11f076e55e7%3A0xa63ecd6438767928!2sSouth%20Quarter%20Tower%20C!5e0!3m2!1sen!2sid!4v1700904019637!5m2!1sen!2sid"
                        className="md:w-2/3"
                        height="250"
                        allowFullScreen
                        loading="lazy"
                        referrerPolicy="no-referrer-when-downgrade"
                    ></iframe>
                    <form
                        className="flex flex-col gap-2 md:w-1/3"
                        action=""
                        method="post"
                        encType="text/plain"
                    >
                        <input
                            type="text"
                            placeholder="Subject"
                            className="border border-slate-400 rounded-md p-1 w-full"
                            name="subject"
                        />
                        <textarea
                            type="text"
                            placeholder="Content"
                            className="border border-slate-400 rounded-md p-1 h-36 md:h-2/3 w-full resize-none"
                            name="body"
                        />
                        <button
                            className="text-white bg-black rounded-md p-1"
                            type="submit"
                            onClick={(e) => {
                                e.preventDefault();
                                const subject =
                                    document.querySelector(
                                        '[name="subject"]'
                                    ).value;
                                const body =
                                    document.querySelector(
                                        '[name="body"]'
                                    ).value;
                                const recipientEmail =
                                    "info@gymnasticsindonesia.id";
                                const mailtoLink = `mailto:${recipientEmail}?subject=${encodeURIComponent(
                                    subject
                                )}&body=${encodeURIComponent(body)}`;
                                window.location.href = mailtoLink;
                            }}
                        >
                            SEND
                        </button>
                    </form>
                </div>
            </section>
            <Footer />
        </>
    );
}
