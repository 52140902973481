/**
 * @description used to format the event's date like 'Sep 9'
 * @param {*} inputDate 
 * @returns string
 */
export function formatEventDate(inputDate) {

    const options = { day: "numeric", month: "short" };
    const eventDate = new Date(inputDate);

    const formattedDate = eventDate.toLocaleDateString("en-US", options);
    const [day, month] = formattedDate.split(" ");

    return [day, month];
}

/**
 * @description used to format the event's start to end date like '5 - 9 September 2023'
 * @param {*} dateStart 
 * @param {*} dateEnd 
 * @returns string
 */
export function formatEventDateRange(dateStart, dateEnd) {

    const options = { year: "numeric", month: "long" };
    const startDate = new Date(dateStart);

    if (dateEnd === "TBA") {
        // If dateEnd is "TBA," return only the formatted startDate (year)
        if (/^\d{4}$/.test(dateStart)) {
            // If dateStart is a 4-digit year, return only the year
            return startDate.toLocaleDateString("en-US", { year: "numeric" });
        }
        return startDate.toLocaleDateString("en-US", options);
    }

    const endDate = new Date(dateEnd);

    const startDay = startDate.getDate();
    const startMonth = startDate.toLocaleDateString("en-US", {
        month: "short",
    });
    const startYear = startDate.getFullYear();

    const endDay = endDate.getDate();
    const endMonth = endDate.toLocaleDateString("en-US", { month: "short" });
    const endYear = endDate.getFullYear();

    if (
        dateEnd === "TBA" &&
        dateStart.match(/^\d{4}$/) // Check if dateStart is a 4-digit year
    ) {
        // If dateEnd is "TBA" and dateStart is a year, return only the year
        return startYear;
    }

    if (
        startDate.getMonth() === endDate.getMonth() &&
        startDate.getFullYear() === endDate.getFullYear()
    ) {
        // If month and year are the same, return a string like '5 - 9 September 2023'
        return `${startDay} - ${endDay} ${startMonth} ${startYear}`;
    } else if (startDate.getFullYear() === endDate.getFullYear()) {
        // If only the year is the same, return a string like '30 Nov - 2 Dec 2023'
        return `${startDay} ${startMonth} - ${endDay} ${endMonth} ${startYear}`;
    } else {
        // Otherwise, return a string like '31 Dec 2023 - 5 Jan 2024'
        return `${startDay} ${startMonth} ${startYear} - ${endDay} ${endMonth} ${endYear}`;
    }
}

/**
 * @description used to format dat to its locale date string
 * @param {*} inputDate 
 * @returns string
 */
export function formatDate(inputDate, currentLocale) {
    const options = { day: "numeric", month: "long", year: "numeric" };
    const date = new Date(inputDate);

    return date.toLocaleDateString(currentLocale || "id-ID", options);
};

export const formatTag = (tag) => {
    return tag
        .split('-')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');
};