import React from "react";

// import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
// import {faLocationDot} from "@fortawesome/free-solid-svg-icons";

import { formatEventDate, formatEventDateRange } from "../utils/format";

const UpcomingEvents = () => {
    // di API nya ambil max 4, sort by upcoming start date
    var eventExample = [
        {
            dateStart: "TBA",
            dateEnd: "TBA",
            name: "Lagi-lagi Gymnastics",
            location: "SQ",
        },
        {
            dateStart: "2024",
            dateEnd: "TBA",
            name: "Indonesia Gymnastics 2024",
            location: "GBK",
        },
        {
            dateStart: "TBA",
            dateEnd: "TBA",
            name: "Indonesia Parkour Tour",
            location: "Jogjakarta",
        },
        {
            dateStart: "TBA",
            dateEnd: "TBA",
            name: "3rd INA Open",
            location: "GOR Radin Inten",
        },
    ];

    return (
        <>
            <div className="md:grid md:grid-cols-2 gap-2">
                {eventExample.map((slide, index) => (
                    <div
                        key={index}
                        className="bg-white p-4 mb-2 rounded-md flex flex-row gap-4 min-w-[18rem] items-center"
                    >
                        <div className="flex flex-col h-20 w-20 items-center justify-center border border-black rounded-md">
                            {(slide.dateStart === "TBA" && slide.dateEnd === "TBA") ? (
                                <span className="font-bold text-2xl">TBA</span>
                            ) : (slide.dateStart !== "TBA" && slide.dateEnd === "TBA") ? (
                                <>
                                    <span className="font-bold text-2xl">
                                        {slide.dateStart}
                                    </span>
                                </>
                            ) : (
                                <>
                                    <span className="font-bold text-2xl">
                                        {formatEventDate(slide.dateStart)[1]}
                                    </span>
                                    <span>
                                        {formatEventDate(slide.dateStart)[0]}
                                    </span>
                                </>
                            )}
                        </div>
                        <div className="flex flex-col">
                            <span className="font-bold">{slide.name}</span>
                            {slide.dateStart === "TBA" ? (
                                <span className="font-light text-sm">
                                    Coming Soon
                                </span>
                            ) : (
                                <span className="font-light text-sm">
                                    {formatEventDateRange(
                                        slide.dateStart,
                                        slide.dateEnd
                                    )}
                                </span>
                            )}

                            <span className="font-light">
                                <i className="fa-solid fa-location-dot"></i>
                                &ensp;{slide.location}
                            </span>
                        </div>
                    </div>
                ))}
            </div>
        </>
    );
};

export default UpcomingEvents;
