import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import Sidebar from "../../components/admin/Sidebar";
import Title from "../../components/admin/Title";
import PrimaryButton from "../../components/admin/PrimaryButton";
import NewsTable from "../../components/admin/Table/NewsTable";
import SecondaryButton from "../../components/admin/SecondaryButton";
import useAuthentication from "../../utils/useAuthentication";
import LoadingComponent from "../../components/Loading";

export default function AdminDashboard() {
  const loading = useAuthentication();
  const navigate = useNavigate();

  const [news, setNews] = useState([]);

  useEffect(() => {
    const fetchNews = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API}/api/news`);
        if (response.ok) {
          const data = await response.json();
          data.sort((a, b) => new Date(b.date) - new Date(a.date));
          setNews(data);
        } else {
          console.error("Failed to fetch news");
        }
      } catch (error) {
        console.error("Error fetching news:", error);
      }
    };

    fetchNews();
  }, []);

  if (loading){
    return <LoadingComponent />
  }

  return (
    <>
      <div className="bg-blue-50 w-100">
        <div className="flex flex-row">
          <Sidebar />
          <div className="m-4 mr-8 w-full">
            <Title title="Dashboard Overview" />
            <div className="bg-white my-4 rounded">
              {/* heid */}
              <div className="flex justify-between border-b-2 border-sky-100 px-4 pt-4 pb-2">
                <h4 className="font-semibold text-lg">Latest News</h4>
                <PrimaryButton
                  name="Create News"
                  icon=""
                  onClick={() => navigate("/admin/create-news")}
                />
              </div>
              {/* bod */}
              <div className="container mx-auto py-4 flex flex-col gap-4 justify-between items-end">
                <NewsTable isDashboard={true} news={news} />
                <div className="mx-4">
                  <SecondaryButton
                    name="See All News"
                    onClick={() => navigate("/admin/news")}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
