// import {getTranslationProps} from "@/utils/translations";
// import { useTranslation } from "react-i18next";
import { useTranslation } from "react-i18next";

import Navbar from "../components/Navbar";
import Carousel from "../components/Carousel";
import NewsCarousel from "../components/NewsCarousel";
import UpcomingEvents from "../components/UpcomingEvents";
import FeaturedAthletes from "../components/FeaturedAthletes";
import Gallery from "../components/Gallery";
import FAQ from "../components/FAQ";
import Footer from "../components/Footer";
import { getLocalizedHref } from "../utils/localization";
import { useNavigate } from "react-router-dom";

// import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
// import {faChevronRight} from "@fortawesome/free-solid-svg-icons";

export default function Home() {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const handleNavigation = (path) => {
        const localizedPath = getLocalizedHref(path);
        console.log('localizedPath: ', localizedPath)
        navigate(localizedPath);
    };

    return (
        <>
            <Navbar />
            <Carousel />

            <section id="news" className="md:px-20 mx-4 lg:mx-36 my-8">
                <div className="flex justify-between">
                    <h2 className="text-xl">
                        <b>{t("heading.latest-news")}</b>
                    </h2>
                    <button className="text-red-700" onClick={() => handleNavigation('/news')}>
                        <b>{t("button.see-all")}</b>&ensp;
                    </button>
                </div>
                <NewsCarousel />
            </section>

            <section
                id="events"
                className="px-8 md:px-24 py-8 lg:px-56 bg-red-700"
            >
                <div className="flex justify-between mb-6">
                    <h2 className="text-xl text-white">
                        <b>{t("heading.upcoming-events")}</b>
                    </h2>
                    <button className="text-white opacity-75 hover:opacity-100">
                        {t("button.see-all")}&ensp;
                        {/* <FontAwesomeIcon
                            className="text-sm"
                            icon={faChevronRight}
                        /> */}
                    </button>
                </div>
                <UpcomingEvents />
            </section>

            <section id="athletes" className="px-4 md:px-20 mx-4 lg:mx-36 my-8">
                <div className="flex justify-between">
                    <h2 className="text-xl">
                        <b>{t("heading.featured-athletes")}</b>
                    </h2>
                </div>
                <FeaturedAthletes />
            </section>

            {/* <section
                id="socials"
                className="px-8 md:px-24 py-8 lg:px-56 bg-black"
            >
                <div className="flex justify-between mb-6">
                    <h2 className="text-xl text-white">
                        <b>{t("heading.social-connect")}</b>
                    </h2>
                </div>
            </section> */}

            <section id="gallery" className="px-4 md:px-20 mx-4 lg:mx-36 my-8">
                <div className="flex justify-between">
                    <h2 className="text-xl">
                        <b>{t("heading.gallery")}</b>
                    </h2>
                </div>
                <Gallery />
            </section>

            <hr />

            <section id="faq" className="px-4 md:px-20 mx-4 lg:mx-36 my-8">
                <div className="flex justify-between">
                    <h2 className="text-xl">
                        <b>{t("heading.faq")}</b>
                    </h2>
                </div>
                <FAQ />
            </section>

            <hr />

            <section id="sponsors" className="px-8 md:px-24 lg:mx-36 my-8">
                <div className="flex justify-center mb-4">
                    <h2 className="text-xl">Gymnastics Indonesia’s Partners</h2>
                </div>
                <div className="flex flex-wrap justify-center gap-4 md:gap-8 my-8">
                    <img className="h-20" src="/images/partners/AGU-logo.jpg" alt="AGU" />
                    <img className="h-20" src="/images/partners/FIG-logo.jpg" alt="FIG" />
                    <img className="h-20" src="/images/partners/kemenpora.png" alt="Kemenpora" />
                    <img className="h-20" src="/images/partners/koni.png" alt="KONI" />
                    <img className="h-20" src="/images/partners/NOCI.png" alt="NOCI" />
                    <img className="w-28 h-20" src="/images/partners/pegadaian.png" alt="Pegadaian" />
                    <img className="w-28 h-20" src="/images/partners/taspen.png" alt="Taspen" />
                </div>
            </section>

            <Footer />
        </>
    );
}
