import React from "react";

const FeaturedAthletes = () => {
    var athleteExamples = [
        {
            name: "Rifda Irfanaluthfi",
            title: "Artistic Gymnastics",
            achievement: "Participant of Olympic Paris 2024",
            picture: "/images/athletes/rifda.jpg",
            link: "/",
        },
        {
            name: "Joseph Sianturi",
            title: "Men's Artistic Gymnastics",
            achievement: "SEA Games 2022 Silver Medalist",
            picture: "/images/athletes/joseph.jpg",
            link: "/",
        },
        {
            name: "Dexie",
            title: "Aerobic Gymnastics",
            achievement: "PON 2022 Gold Medalist",
            picture: "/images/athletes/dexie.jpg",
            link: "/",
        },
        {
            name: "Abiyu Rafi",
            title: "Men's Artistic Gymnastics",
            achievement: "SEA Games 2022 Bronze Medalist",
            picture: "/images/athletes/abiyu.jpg",
            link: "/",
        },
        {
            name: "Tri Wahyuni",
            title: "Rythmic Gymnast",
            achievement: "Winner of Rythmic World Championship 2023",
            picture: "/images/athletes/tri_wahyuni.jpg",
            link: "/",
        },
        {
            name: "Sutjiati Narendra",
            title: "Rythmic Gymnast",
            achievement: "PON 2022 Gold Medalist",
            picture: "/images/athletes/suci.jpg",
            link: "/",
        },
    ];

    return (
        <>
            <div className="mt-4 grid gap-4 grid-cols-2 sm:gap-8 md:grid-cols-3 md:gap-2">
                {athleteExamples.map((athlete, index) => (
                    <div
                        key={index}
                        className="relative h-80 md:h-96 text-white shadow-[inset_0_0_0_2000px_rgba(0,0,0,0.3)] hover:shadow-[inset_0_0_0_2000px_rgba(0,0,0,0)] hover:cursor-pointer"
                        style={{
                            backgroundImage: `url(${athlete.picture})`,
                            backgroundSize: "cover",
                            backgroundPosition: "center",
                        }}
                    >
                        <div className={`absolute bottom-0 left-0 w-full h-[55%] md:h-[45%] z-10 polygon opacity-60 ${athlete.title === "Men's Artistic Gymnastics" ? "bg-cyan-700" : athlete.title === "Artistic Gymnastics" ? "bg-red-700" : athlete.title === "Aerobic Gymnastics" ? "bg-amber-700" : "bg-pink-700"}`}></div>

                        <div className="absolute top-[45%] sm:top-[60%] mx-auto w-full z-20 text-center mb-8">
                            <div className="flex flex-col text-center">
                                <span className="font-bold text-xl md:text-2xl">
                                    {athlete.name}
                                </span>
                                <span className="font-sm font-light">
                                    {athlete.title}
                                </span>
                            </div>

                            <span className="font-sm text-center mt-4">
                                {athlete.achievement}
                            </span>
                        </div>
                    </div>
                ))}
            </div>
        </>
    );
};
export default FeaturedAthletes;
