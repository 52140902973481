import React, { useState, useEffect } from "react";
import IndicatorButtons from "./buttons/IndicatorButtons";
import { useTranslation } from "react-i18next";
import { getLocalizedHref } from "../utils/localization";
import { formatDate } from "../utils/format";
import LoadingComponent from "./Loading";

const NewsCarousel = () => {
  const { i18n } = useTranslation();
  const newsPerPage = 3; // Number of news per page
  const [currentPage, setCurrentPage] = useState(1);
  const [newsData, setNewsData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchNews = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API}/api/news?isDisplayed=1`
        );
        if (response.ok) {
          const data = await response.json();
          data.sort((a, b) => new Date(b.date) - new Date(a.date));
          setNewsData(data.slice(0, 9));
        } else {
          console.error("Failed to fetch news:", response.statusText);
        }
      } catch (error) {
        console.error("Error fetching news:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchNews();
  }, []);

  // Calculate the start and end index for the current page
  const startIndex = (currentPage - 1) * newsPerPage;
  const endIndex = startIndex + newsPerPage;

  // Get the subset of news data for the current page
  const currentNews = newsData.slice(startIndex, endIndex);

  // Function to handle clicking on the indicator buttons
  const handleIndicatorClick = (page) => {
    setCurrentPage(page);
  };

  if (loading) {
    return <LoadingComponent />;
  }

  return (
    <>
      <div className="mt-6 grid gap-4 grid-cols-1 sm:grid-cols-3">
        {currentNews.map((slide, index) => (
          <a
            className="flex flex-row sm:flex-col sm:w-54 mr-4"
            key={index}
            href={getLocalizedHref(`/news/${slide.slug}`)}
          >
            <img
              src={`${process.env.REACT_APP_API}/${slide.image}`}
              className="object-cover rounded-lg w-32 h-32 sm:w-60 sm:h-60 mr-2 sm:mb-2"
              alt={i18n.language === "en" ? slide.titleEN : slide.titleID}
            />
            <div className="flex flex-col">
              <span className="text-sm font-light">
                {formatDate(slide.date, i18n.language)}
              </span>
              <h4 className="font-semibold text-lg">
                {i18n.language === "en"
                  ? slide.titleEN.length > 20
                    ? `${slide.titleEN.substring(0, 20)}...`
                    : slide.titleEN
                  : slide.titleID.length > 20
                    ? `${slide.titleID.substring(0, 20)}...`
                    : slide.titleID}
              </h4>
              <span
                className="font-light"
                dangerouslySetInnerHTML={{
                  __html:
                    i18n.language === "en"
                      ? slide.articleEN.length > 60
                        ? `${slide.articleEN.substring(0, 60)}...`
                        : slide.articleEN
                      : slide.articleID.length > 60
                        ? `${slide.articleID.substring(0, 60)}...`
                        : slide.articleID,
                }}
              />
            </div>
          </a>
        ))}
      </div>
      {/* Indicator Buttons */}
      {newsData.length > newsPerPage && (
        <IndicatorButtons
          length={Math.ceil(newsData.length / newsPerPage)}
          onClick={handleIndicatorClick}
          currentSlide={currentPage}
        />
      )}
    </>
  );
};

export default NewsCarousel;
