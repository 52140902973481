// utils/useAuthentication.js
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";

const useAuthentication = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true); // Loading state

  useEffect(() => {
    const checkToken = async () => {
      const token = Cookies.get("token");

      if (!token || token === undefined) {
        navigate("/admin/login");
      } else {
        setIsLoading(false); // Set loading to false once authentication check is complete
      }
    };

    checkToken();
  }, [navigate]);

  return isLoading; // Return loading state
};

export default useAuthentication;
