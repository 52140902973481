import Navbar from "../components/Navbar";
import Footer from "../components/Footer";

export default function Disciplines() {
    return (
        <>
            <Navbar />
            <div className="min-h-screen py-20 flex flex-col">
                <div
                    className="transition-opacity flex opacity-100 hover:cursor-pointer"
                    style={{
                        backgroundImage: "url(/images/disciplines/discipline-all.png)",
                        boxShadow: "inset 0 0 0 2000px rgba(0, 0, 0, 0.5)",
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                        width: "100%",
                        height: "100%",
                    }}
                >
                    <div className="flex flex-col items-start w-full text-center px-8 py-28 m-auto">
                        <h1 className="text-3xl font-bold text-white">
                            Gymnastics For All
                        </h1>
                    </div>
                </div>
                <div
                    className="transition-opacity flex opacity-100 hover:cursor-pointer"
                    style={{
                        backgroundImage: "url(/images/disciplines/discipline-mag.png)",
                        boxShadow: "inset 0 0 0 2000px rgba(0, 0, 0, 0.5)",
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                        width: "100%",
                        height: "100%",
                    }}
                >
                    <div className="flex flex-col items-end w-full text-center px-8 py-28 m-auto">
                        <h1 className="text-3xl font-bold text-white">
                            Men's Artistic Gymnastics
                        </h1>
                    </div>
                </div>
                <div
                    className="transition-opacity flex opacity-100 hover:cursor-pointer"
                    style={{
                        backgroundImage: "url(/images/disciplines/discipline-wag.png)",
                        boxShadow: "inset 0 0 0 2000px rgba(0, 0, 0, 0.5)",
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                        width: "100%",
                        height: "100%",
                    }}
                >
                    <div className="flex flex-col items-start w-full text-center px-8 py-28 m-auto">
                        <h1 className="text-3xl font-bold text-white">
                            Women's Artistic Gymnastics
                        </h1>
                    </div>
                </div>
                <div
                    className="transition-opacity flex opacity-100 hover:cursor-pointer"
                    style={{
                        backgroundImage: "url(/images/disciplines/discipline-rythmic.png)",
                        boxShadow: "inset 0 0 0 2000px rgba(0, 0, 0, 0.5)",
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                        width: "100%",
                        height: "100%",
                    }}
                >
                    <div className="flex flex-col items-end w-full text-center px-8 py-28 m-auto">
                        <h1 className="text-3xl font-bold text-white">
                            Rythmic Gymnastics
                        </h1>
                    </div>
                </div>
                <div
                    className="transition-opacity flex opacity-100 hover:cursor-pointer"
                    style={{
                        backgroundImage:
                            "url(/images/disciplines/discipline-trampoline.png)",
                        boxShadow: "inset 0 0 0 2000px rgba(0, 0, 0, 0.5)",
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                        width: "100%",
                        height: "100%",
                    }}
                >
                    <div className="flex flex-col items-start w-full text-center px-8 py-28 m-auto">
                        <h1 className="text-3xl font-bold text-white">
                            Trampoline Gymnastics
                        </h1>
                    </div>
                </div>
                <div
                    className="transition-opacity flex opacity-100 hover:cursor-pointer"
                    style={{
                        backgroundImage:
                            "url(/images/disciplines/discipline-acrobatic.png)",
                        boxShadow: "inset 0 0 0 2000px rgba(0, 0, 0, 0.5)",
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                        width: "100%",
                        height: "100%",
                    }}
                >
                    <div className="flex flex-col items-end w-full text-center px-8 py-28 m-auto">
                        <h1 className="text-3xl font-bold text-white">
                            Acrobatic Gymnastics
                        </h1>
                    </div>
                </div>
                <div
                    className="transition-opacity flex opacity-100 hover:cursor-pointer"
                    style={{
                        backgroundImage: "url(/images/disciplines/discipline-aerobic.png)",
                        boxShadow: "inset 0 0 0 2000px rgba(0, 0, 0, 0.5)",
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                        width: "100%",
                        height: "100%",
                    }}
                >
                    <div className="flex flex-col items-start w-full text-center px-8 py-28 m-auto">
                        <h1 className="text-3xl font-bold text-white">
                            Aerobic Gymnastics
                        </h1>
                    </div>
                </div>
                <div
                    className="transition-opacity flex opacity-100 hover:cursor-pointer"
                    style={{
                        backgroundImage: "url(/images/disciplines/discipline-parkour.png)",
                        boxShadow: "inset 0 0 0 2000px rgba(0, 0, 0, 0.5)",
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                        width: "100%",
                        height: "100%",
                    }}
                >
                    <div className="flex flex-col items-end w-full text-center px-8 py-28 m-auto">
                        <h1 className="text-3xl font-bold text-white">
                            Parkour Gymnastics
                        </h1>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
}